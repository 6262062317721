import {
  Button,
  Flex,
  FormControl,
  FormLabel, Radio,
  RadioGroup, StackDivider, Text,
  VStack
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { useThrottle } from 'rooks';
import { useFunnel } from '../../../funnel/Funnel';
import { useI18n } from '../../../../lib/hooks/I18n';

const RefundPaymentDateStep = () => {
  const { t } = useI18n();
  const { stepValues, goForward, goBack } = useFunnel();

  const options = [
    { value: 0, label: t('fields.refund_days_since_purchase.options.today') },
    { value: 1, label: t('fields.refund_days_since_purchase.options.yesterday') },
    { value: 2, label: t('fields.refund_days_since_purchase.options.two_days_ago') },
    { value: 3, label: t('fields.refund_days_since_purchase.options.more_than_two_days_ago') },
  ];

  const { register, getValues, handleSubmit, formState: { errors, isSubmitted, isValid, isDirty }} = useForm<any>({
    mode: 'all',
    defaultValues: stepValues()
  });

  const [onSubmit] = useThrottle((data: any) => {
    goForward(data);
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="refund_days_since_purchase" isInvalid={!!errors.refund_days_since_purchase}>
        <FormLabel variant="heavy">{ t('fields.refund_days_since_purchase.label') }</FormLabel>
        <Text fontSize={13} color="gray" mt={-2} mb={3}>{ t('fields.refund_days_since_purchase.text') }</Text>
        <RadioGroup defaultValue={getValues('refund_days_since_purchase')}>
          <VStack
            spacing="0"
            mb="4"
            overflow="hidden"
            backgroundColor="gray.100"
            borderRadius="2xl"
            boxShadow="xl"
            alignItems="left"
            divider={<StackDivider borderColor="gray.300" />}
          >
            {
              options.map((option, index) =>
                <Radio
                  key={index}
                  value={String(option.value)}
                  px="5" py="4"
                  {...register('refund_days_since_purchase', { required: true })}
                >{option.label}</Radio>
              )
            }
          </VStack>
        </RadioGroup>
      </FormControl>

      <Flex justifyContent="space-between" mt="6">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())}>
          { t('common.buttons.back') }
        </Button>
        <Button
          variant={ isValid && isDirty ? 'solid' : 'ghost'}
          size="lg"
          type="submit"
          disabled={isSubmitted && !isValid}
          rightIcon={<ArrowForwardIcon boxSize="6"/>}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>
    </form>
  );
};

export default RefundPaymentDateStep;
