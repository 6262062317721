import { Button, Link, Text, VStack } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { usePageData } from 'lib/hooks/PageDataContext';
import { GoogleIcon } from '../../icons/GoogleIcon';
import Footer from '../../layout/Footer';
import { useFunnel } from '../../funnel/Funnel';
import { hasPreAuthPeriodExceeded, paymentMethodHasPreAuth } from '../../funnel/help-funnel-steps';
import { useI18n } from '../../../lib/hooks/I18n';
import { useRouter } from 'next/router';

const SuccessStep = () => {
  const { t } = useI18n();
  const { query } = useRouter();
  const { owner, settings } = usePageData();
  const { values } = useFunnel();

  const goToStart = () => {
    let url = `/${owner.company_url_slug}`;

    if (query.lang) {
      url += `?lang=${query.lang}`;
    }

    return window?.location?.assign(url);
  }

  const helpContent = () => {
    let content;
    const withinPreAuthPeriodExceeded = !hasPreAuthPeriodExceeded({ owner }, values) && paymentMethodHasPreAuth({ owner }, values);

    if (withinPreAuthPeriodExceeded) {
      content = <Text fontSize="lg" align="center">{ t('steps.success.help.text_within_pre_auth_period_exceeded') }</Text>;
    } else {
      content = <Text fontSize="lg" align="center">{ t('steps.success.text') }</Text>;
    }

    return <>
      {content}
      <Button size="lg" onClick={goToStart}>
        { t('steps.success.buttons.okay') }
      </Button>
    </>;
  };

  const feedbackWithoutExternalContent = () => <>
    <Text fontSize="lg" align="center">{ t('steps.success.feedback.text_without_external_review') }</Text>
    <Button size="lg" onClick={goToStart}>
      { t('steps.success.buttons.cool') }
    </Button>
  </>;

  const feedbackWithExternalContent = () => <>
    <Text fontSize="lg" align="center">{ t('steps.success.feedback.text_with_external_review') }</Text>

    <GoogleIcon w="55" h="55" />
    <Link href={settings.feedback_review_url} isExternal>
      <Button size="lg">{ t('steps.success.buttons.leave_a_review') }</Button>
    </Link>
    <Button size="lg" variant="ghost" onClick={goToStart}>
      { t('steps.success.buttons.no_thanks') }
    </Button>
  </>;

  const refundContent = () => {
    return <>
      <Text fontSize="lg" align="center">{ t('steps.success.text') }</Text>
      <Button size="lg" onClick={goToStart}>
        { t('steps.success.buttons.okay') }
      </Button>
    </>;
  };

  const renderResult = () => {
    if (values.type === 'help') {
      return helpContent();
    } else if (values.type === 'feedback') {
      if (settings.feedback_external_active && values.rating >= settings.feedback_review_threshold) {
        return feedbackWithExternalContent();
      }

      return feedbackWithoutExternalContent();
    } else if (values.type === 'refund') {
      return refundContent();
    }
  }

  return (
    <>
      <VStack spacing="6" pb="10">
        <Text fontSize="2xl">{ t('steps.success.title', { name: values.name }) }</Text>
        <CheckCircleIcon color="brand.500" w="113" h="113" />
        { renderResult() }
      </VStack>

      <Footer />
    </>
  );
};

export default SuccessStep;
